@use '@ag-grid-community/styles' as ag;

.ag-theme-fleo {
  @include body2;
  height: 100%;
  width: 100%;

  // Hide filter indicator when filter is active
  .ag-header-label-icon.ag-filter-icon {
    display: none;
  }

  .ag-cell-label-container {
    gap: $spacing-small;
  }

  .ag-filter {
    .ag-mini-filter {
      padding: 0 calc($spacing-medium + 12px) 0 $spacing-medium;
      margin: 0;
      height: 56px;
      border-bottom: 1px solid rgb(var(--secondary-medium-light-color));
      margin-bottom: $spacing-small;
      position: relative;

      .ag-input-field-input.ag-text-field-input {
        font-size: $font-size-s;
        border: 0;
        padding-left: 0;
        padding-right: 0;
        &:focus {
          border-bottom: none;
        }
      }

      &:before {
        @extend .fa-regular;
        content: unquote('"#{ $fa-var-magnifying-glass }"');
        position: absolute;
        right: 12px;
        top: 0;
        bottom: 0;
        align-content: center;
      }
    }

    .ag-filter-apply-panel {
      @include flexRowEndCenter;
      gap: $spacing-small;
      border-top: solid 1px rgb(var(--secondary-medium-light-color));
      padding: $spacing-medium;
      .ag-standard-button {
        @extend .btn;
        @extend .btn-s;
        @extend .btn-primary;
        margin: 0;
        text-transform: none;
        order: 1;
        &[ref='resetFilterButton'] {
          @extend .btn-type-secondary;
          order: 0;
        }
      }
    }

    @include ag.text-input {
      @extend .form-control;
      border: 1px solid $secondary-light-color;
    }

    .ag-input-wrapper {
      padding-right: 0;

      &:before {
        content: '';
      }
    }

    .ag-filter-select .ag-picker-field-wrapper {
      @extend .form-control;
      display: flex;

      .ag-picker-field-display {
        margin: 0;
      }
    }
  }

  .ag-header-cell-label {
    @include body3;
    display: flex;

    .ag-header-cell-text {
      flex-grow: 2;
    }

    .ag-filter-icon,
    .ag-sort-indicator-container {
      .ag-icon {
        color: $text-80-color;
      }
    }
  }
  .ag-popup {
    position: absolute;
  }

  // Hide sidepanel drag handel per column
  .ag-column-select-column-drag-handle,
  .ag-column-select-column-group-drag-handle {
    display: none;
  }

  button.ag-side-button-button {
    min-height: 110px; // make button more compact
    border: none;
  }

  .ag-side-button-icon-wrapper {
    color: $primary-color;
    margin-bottom: 6px;
  }
  // Style side panel button
  .ag-tool-panel-wrapper {
    box-shadow: $shadow-8;

    .ag-column-panel-column-select {
      border: 0;
    }
    .ag-text-field-input-wrapper {
      position: relative;

      padding-right: (2 * $spacing-small + 14px);

      &::before {
        content: '\f002';
        font-family: $font-family-fa-icons;
        position: absolute;
        right: 0;
        margin: auto $spacing-small;
      }
    }
    input[class^='ag-'][type='text'] {
      @extend .form-control;

      border: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }
  .ag-side-buttons {
    border-left: 1px solid $border-main-light-color;
    width: 22px;
  }
  .ag-cell-first-right-pinned,
  .ag-side-bar-right {
    border-left: none !important;
  }

  .ag-row-focus {
    background-color: $select-hover-color;
  }

  // Restyle checkbox
  .ag-checkbox-input-wrapper {
    @extend .pseudo-checkbox;
    pointer-events: none;

    .ag-checkbox-input {
      pointer-events: all;
      cursor: pointer;
      opacity: 0.01;

      &:disabled {
        cursor: default;
      }
    }

    &.ag-checked {
      @extend .pseudo-checkbox-checked;
    }

    &.ag-indeterminate {
      @extend .pseudo-checkbox-indeterminate;
    }

    &.ag-disabled {
      @extend .pseudo-checkbox-disabled;
    }

    &:hover {
      &.ag-checked,
      &.ag-indeterminate {
        color: rgb(var(--primary-hover-color));
      }
    }

    &:active {
      &.ag-checked,
      &.ag-indeterminate {
        color: rgb(var(--primary-pressed-color));
      }
    }
  }
}
