@import './variables';

.os-theme-fleo {
  --os-size: 10px;
  --os-padding-perpendicular: 2px;
  --os-padding-axis: 0;

  --os-track-bg: none;
  --os-track-bg-hover: rgba(#{$secondary-medium-color}, 0.1);
  --os-track-bg-active: none;
  --os-track-border: none;
  --os-track-border-hover: none;
  --os-track-border-active: none;
  --os-track-border-radius: 0;

  --os-handle-bg: #{$secondary-medium-color};
  --os-handle-bg-hover: #{$secondary-medium-dark-color};
  --os-handle-bg-active: #{$secondary-medium-dark-color};
  --os-handle-border: none;
  --os-handle-border-hover: none;
  --os-handle-border-active: none;
  --os-handle-border-radius: 6px;
  --os-handle-min-size: 32px;
  --os-handle-max-size: none;
  --os-handle-perpendicular-size: 100%;
  --os-handle-perpendicular-size-hover: 100%;
  --os-handle-perpendicular-size-active: 100%;
  --os-handle-interactive-area-offset: 0;
}

.os-scrollbar {
  z-index: 900;
}
