$itemHeight: 36px;

#userlane-assistant-container {
  z-index: 999;
  left: 11px;
  bottom: 106px;
  height: $itemHeight;
  min-height: $itemHeight;
  width: $itemHeight;

  &:hover {
    z-index: 999;
  }

  div[data-usln="player-avatar-component"] {
    width: $itemHeight;
    height: $itemHeight;

    img {
      width: $itemHeight;
      height: $itemHeight;
    }
  }
}

#userlane-backdrop-full {
  z-index: 998 !important;
}
