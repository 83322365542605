@use '@ag-grid-community/styles' as ag;
// deprecated data-table
.ag-theme-fleopard {
  @include body2;
  --ag-widget-container-horizontal-padding: #{$spacing-medium};
  --ag-widget-container-vertical-padding: #{$spacing-medium};

  .ag-header-cell-resize::after,
  .ag-header-cell.ag-header-span-height .ag-header-cell-resize::after {
    height: 100%;
    top: 0;
  }

  .ag-root-wrapper {
    overflow: visible;
  }

  .ag-cell-value {
    flex: 1; // required if checkboxes are enabled
    padding: 0 $spacing-medium;
    line-height: calc(var(--ag-line-height) - 3px); // vertically align content without breaking the ellipsis
  }

  .cell-empty {
    @include notAvailable;
  }

  &.ag-dnd-ghost {
    cursor: grabbing;
  }

  .ag-side-button-icon-wrapper {
    color: rgb(var(--primary-color));
    margin-bottom: 6px;
  }

  button.ag-side-button-button {
    min-height: 110px;
  }

  .ag-row-hover {
    &.row-clickable {
      cursor: pointer;
    }
  }

  .ag-row-loading {
    pointer-events: none;
  }

  .ag-row-focus {
    background-color: $select-hover-color;
  }

  // set background color for focused cells within range selections
  .ag-cell-range-selected,
  .ag-cell-range-selected-1 {
    &.ag-cell-focus {
      background-color: var(--ag-range-selection-background-color);
    }
  }

  .ag-row-hover {
    .ag-cell-range-selected {
      background-color: rgba($select-color, 0.25);
    }
  }

  .ag-pinned-left-cols-container,
  .ag-pinned-right-cols-container {
    z-index: 1;
    box-shadow: $shadow-8;
    clip-path: inset(0 0 0 -20px); // clip bottom shadow if only a few lines are visible
  }

  .ag-center-cols-clipper {
    min-height: max(360px, 100%) !important;
  }

  .row-disabled {
    background-color: $text-10-color;
    opacity: 0.6;
  }

  // Styles for pinned rows
  .ag-floating-top {
    border-bottom: 0;
    /* Ag-Grid sets overflow-y: scroll on the element - so we need to overwrite it with important */
    overflow-y: auto !important;
  }
  .ag-floating-bottom {
    border-top: 0;
  }
  .ag-row-pinned {
    background-color: $background-light-color;
    font-weight: 500;
  }

  /*
   * As of AgGrid 25.3.x, invisible checkboxes receive the class .ag-invisible instead of .ag-hidden.
   * While the header is still being decorated with the .ag-hidden class, we have to override
   * this .ag-invisible class not to break the existing layout for left aligned columns.
   */
  .ag-selection-checkbox.ag-invisible {
    display: none !important;
  }

  .ag-checkbox-input-wrapper {
    @extend .pseudo-checkbox;

    pointer-events: none;
    color: $text-80-color;

    &.ag-checked {
      @extend .pseudo-checkbox-checked;
    }

    &.ag-indeterminate {
      @extend .pseudo-checkbox-indeterminate;
    }

    &.ag-checked,
    &.ag-indeterminate {
      color: rgb(var(--primary-color));
    }

    &.ag-disabled {
      @extend .pseudo-checkbox-disabled;
    }

    &:hover {
      &.ag-checked,
      &.ag-indeterminate {
        color: rgb(var(--primary-hover-color));
      }
    }

    &:active {
      &.ag-checked,
      &.ag-indeterminate {
        color: rgb(var(--primary-pressed-color));
      }
    }
  }

  .ag-checkbox-input {
    pointer-events: all;
    cursor: pointer;
    opacity: 0.01;

    &:disabled {
      cursor: default;
    }
  }

  .ag-tool-panel-wrapper {
    width: $sidepanel-width-xs;
    background-color: $white;
    box-shadow: $shadow-8;

    .ag-column-panel-column-select {
      border: 0;
    }

    .ag-column-select-column-drag-handle,
    .ag-column-select-column-group-drag-handle {
      display: none;
    }

    input[class^='ag-'][type='text'] {
      @extend .form-control;

      border: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .ag-menu {
    border-radius: $border-radius-small;
    border: 1px solid rgb(var(--secondary-medium-light-color));
  }

  .ag-text-field-input-wrapper {
    position: relative;

    padding-right: (2 * $spacing-small + 14px);

    &::before {
      content: '\f002';
      font-family: $font-family-fa-icons;
      position: absolute;
      right: 0;
      margin: auto $spacing-small;
    }
  }

  .ag-filter {
    .ag-mini-filter {
      padding: 0 calc($spacing-medium + 12px) 0 $spacing-medium;
      margin: 0;
      height: 56px;
      border-bottom: 1px solid rgb(var(--secondary-medium-light-color));
      margin-bottom: $spacing-small;
      position: relative;

      .ag-input-field-input.ag-text-field-input {
        font-size: $font-size-s;
        border: 0;
        padding-left: 0;
        padding-right: 0;
        &:focus {
          border-bottom: none;
        }
      }

      &:before {
        @extend .fa-regular;
        content: unquote('"#{ $fa-var-magnifying-glass }"');
        position: absolute;
        right: 12px;
        top: 0;
        bottom: 0;
        align-content: center;
      }
    }

    .ag-filter-apply-panel {
      @include flexRowEndCenter;
      gap: $spacing-small;
      border-top: solid 1px rgb(var(--secondary-medium-light-color));
      padding: $spacing-medium;
      .ag-standard-button {
        @extend .btn;
        @extend .btn-s;
        @extend .btn-primary;
        margin: 0;
        text-transform: none;
        order: 1;
        &[ref='resetFilterButton'] {
          @extend .btn-type-secondary;
          order: 0;
        }
      }
    }

    @include ag.text-input {
      @extend .form-control;
      border: 1px solid $secondary-light-color;
    }

    .ag-input-wrapper {
      padding-right: 0;

      &:before {
        content: '';
      }
    }

    .ag-filter-select .ag-picker-field-wrapper {
      @extend .form-control;
      display: flex;

      .ag-picker-field-display {
        margin: 0;
      }
    }
  }

  .ag-set-filter-item {
    margin: 0;
    overflow: hidden;

    .ag-checkbox.ag-input-field {
      width: 100%;

      .ag-checkbox-label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: calc(100% - 24px);
      }
    }
  }

  .ag-row {
    .ag-row-group {
      cursor: default;
      align-items: center;
      border: none !important; // prevents the focus border to only have the selected row highlighted with the background color
      .ag-group-contracted,
      .ag-group-expanded {
        margin-left: $spacing-medium;
        margin-right: $spacing-small;
      }
    }
  }

  // Create indentation for grouped rows, single column groups indentation is done by AgGrid
  &.ag-grid-group-rows {
    // Support 4 levels of indentation for now
    $rowLevels: 4;
    @for $i from 1 through $rowLevels {
      .ag-full-width-row.ag-row-level-#{$i} {
        > .ag-cell-wrapper {
          padding-left: $spacing-medium-large * $i - $spacing-xx-small;
        }
      }
      .ag-row-level-#{$i} {
        > :first-child {
          padding-left: ($spacing-medium-large * $i);
        }
      }
    }
  }

  // Hide the border of the right pinned cell by default and have the border on the whole container instead
  .ag-cell-first-right-pinned {
    border-left: none !important;
  }
  .ag-pinned-right-cols-container {
    border-left: 1px solid rgb(var(--secondary-medium-light-color));
  }

  .multi-selection-cell {
    @include flexRowCenterCenter;

    .ag-cell-wrapper .ag-selection-checkbox {
      height: auto;
    }

    .ag-cell-value {
      display: none;
    }

    .ag-header-cell-comp-wrapper {
      display: none;
    }
  }
}
