// Framework grid generation
//
// Used only by Bootstrap to generate the correct number of grid classes given
// any value of `$grid-columns`.

@use 'sass:math';

@mixin make-grid-columns($columns: $grid-columns, $gutter: $grid-gutter-width, $breakpoints: $grid-breakpoints) {
  // Common properties for all breakpoints
  %grid-column {
    position: relative;
    width: 100%;
    padding-right: $gutter * 0.5;
    padding-left: $gutter * 0.5;
  }

  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    @if $columns > 0 {
      // Allow columns to stretch full width below their breakpoints
      @for $i from 1 through $columns {
        .col#{$infix}-#{$i} {
          @extend %grid-column;
        }
      }
    }

    .col#{$infix},
    .col#{$infix}-auto {
      @extend %grid-column;
    }

    @include media-breakpoint-up($breakpoint, $breakpoints) {
      // Provide basic `.col-{bp}` classes for equal-width flexbox columns
      .col#{$infix} {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }

      @if $grid-row-columns > 0 {
        @for $i from 1 through $grid-row-columns {
          .row-cols#{$infix}-#{$i} {
            @include row-cols($i);
          }
        }
      }

      .col#{$infix}-auto {
        @include make-col-auto();
      }

      @if $columns > 0 {
        @for $i from 1 through $columns {
          .col#{$infix}-#{$i} {
            @include make-col($i, $columns);
          }
        }
      }

      .order#{$infix}-first {
        order: -1;
      }

      .order#{$infix}-last {
        order: $columns + 1;
      }

      @for $i from 0 through $columns {
        .order#{$infix}-#{$i} {
          order: $i;
        }
      }

      @if $columns > 0 {
        // `$columns - 1` because offsetting by the width of an entire row isn't possible
        @for $i from 0 through ($columns - 1) {
          @if not($infix == '' and $i == 0) {
            // Avoid emitting useless .offset-0
            .offset#{$infix}-#{$i} {
              @include make-col-offset($i, $columns);
            }
          }
        }
      }
    }
  }
}

/// Grid system
//
// Generate semantic grid columns with these mixins.

@mixin make-container($gutter: $grid-gutter-width) {
  width: 100%;
  padding-right: $gutter * 0.5;
  padding-left: $gutter * 0.5;
  margin-right: auto;
  margin-left: auto;
}

@mixin make-row($gutter: $grid-gutter-width) {
  display: flex;
  flex-wrap: wrap;
  margin-right: -$gutter * 0.5;
  margin-left: -$gutter * 0.5;
}

@mixin make-col-ready($gutter: $grid-gutter-width) {
  position: relative;
  // Prevent columns from becoming too narrow when at smaller grid tiers by
  // always setting `width: 100%;`. This works because we use `flex` values
  // later on to override this initial width.
  width: 100%;
  padding-right: $gutter * 0.5;
  padding-left: $gutter * 0.5;
}

@mixin make-col($size, $columns: $grid-columns) {
  flex: 0 0 percentage(math.div($size, $columns));
  // Add a `max-width` to ensure content within each column does not blow out
  // the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
  // do not appear to require this.
  max-width: percentage(math.div($size, $columns));
}

@mixin make-col-auto() {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; // Reset earlier grid tiers
}

@mixin make-col-offset($size, $columns: $grid-columns) {
  $num: math.div($size, $columns);
  margin-left: if($num == 0, 0, percentage($num));
}

// Row columns
//
// Specify on a parent element(e.g., .row) to force immediate children into NN
// numberof columns. Supports wrapping to new lines, but does not do a Masonry
// style grid.
@mixin row-cols($count) {
  > * {
    flex: 0 0 math.div(100%, $count);
    max-width: math.div(100%, $count);
  }
}
