.side-panel-overlay {
  margin-top: $header-global-height !important;
  max-height: calc(100% - #{$header-global-height}) !important;
}

.menu-overlay {
  min-height: 100%;
}

.overlay-backdrop {
  background-color: $modal-backdrop-color;
}

.notification-overlay {
  position: absolute !important;
  right: 0;
  top: 56px;
  max-height: calc(100% - #{$header-global-height});
  overflow: hidden auto;
  padding: 0;
  box-sizing: border-box;
}

.cdk-overlay-container {
  font-family: $font-family-base;
  font-size: 14px;
}
