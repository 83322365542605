@use 'sass:math';
@use 'sass:map';
@import '../../styles/variables';
@import '../../styles/mixins';

$badge-size: 16px;
$badge-l-size: 20px;
$badge-s-size: 13px;
$badge-font-size: 12px;

.badge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: $badge-size;
  line-height: $badge-size;
  // min width to display pill badges with the same width as height by default
  min-width: $badge-size;
  padding: 0 $spacing-x-small;
  border-radius: $badge-size * 0.5;
  text-transform: uppercase;
  width: fit-content;
  font-size: $badge-font-size;
  font-weight: 500;
  // create ellipsis after max-width of parent container
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;


  &.badge-l {
    height: $badge-l-size;
    line-height: $badge-l-size;
    min-width: $badge-l-size;
    border-radius: $badge-l-size;
  }

  &.badge-s {
    height: $badge-s-size;
    line-height: $badge-s-size;
    min-width: $badge-s-size;
    border-radius: $badge-s-size;
  }


  &.badge-rectangle {
    border-radius: $border-radius-small;
  }

  // Empty badges collapse automatically
  &:empty {
    display: none;
  }

  &.badge-disable {
    background-color: rgb(var(--secondary-medium-color));
    color: $white;
  }

  &.badge-icon {
    padding: 0;
    font-size: 8px;
  }

  &.badge-s.badge-icon {
    font-size: 6px;
  }
}

// Defines the behavior of the overlay
.badge-overlay {
  position: relative;

  .badge {
    position: absolute;
    border: 1px solid white;
  }

  &.badge-disable {
    .badge {
      background-color: rgb(var(--secondary-medium-color));
      color: $white;
    }
  }

  &.badge-hidden {
    .badge {
      display: none;
    }
  }

  &.badge-above {
    .badge {
      top: math.div(-$badge-size, 1.8);
    }
  }

  &.badge-below {
    .badge {
      bottom: math.div(-$badge-size, 1.8);
    }
  }

  &.badge-before {
    .badge {
      left: -$badge-size;
    }
  }

  &.badge-after {
    .badge {
      right: -$badge-size;
    }
  }

  &.badge-overlap {
    &.badge-before {
      .badge {
        left: math.div(-$badge-size, 1.8);
      }
    }

    &.badge-after {
      .badge {
        right: math.div(-$badge-size, 1.8);
      }
      .badge.badge-s {
        right: -2px;
      }
    }

    &.badge-below {
      .badge {
        bottom: math.div(-$badge-size, 1.8);
      }
      .badge.badge-s {
        bottom: -2px;
      }
    }
  }
}
