@use 'sass:map';
@import './rfs';

@mixin h1 {
  font-size: 60px;
  font-weight: 300;
}

@mixin h2 {
  font-size: 44px;
  font-weight: 300;
}

@mixin h3 {
  font-size: 32px;
  font-weight: 400;
}

@mixin h4 {
  font-size: 24px;
  font-weight: 500;
}

@mixin h5 {
  font-size: 20px;
  font-weight: 500;
}

@mixin h6 {
  font-size: 16px;
  font-weight: 700;
}

@mixin h7 {
  font-size: 14px;
  font-weight: 700;
}

@mixin subtitle1 {
  font-size: 16px;
  font-weight: 400;
}

@mixin body1 {
  font-size: 14px;
  font-weight: 400;
}

@mixin body2 {
  font-size: 12px;
  font-weight: 400;
}

@mixin body3 {
  font-size: 12px;
  font-weight: 700;
}

@mixin link2 {
  font-size: 12px;
  font-weight: 500;
  color: $text-color;
}

@mixin caption1 {
  font-size: 11px;
  font-weight: 400;
}

@mixin overline {
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
}

@mixin notAvailable {
  // Needs to be changed to $text-60-color after the missing data concept is in place
  color: $text-color;
}

@mixin ellipsis($lines: 1) {
  @if ($lines > 1) {
    @supports (-webkit-line-clamp: $lines) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: $lines;
      -webkit-box-orient: vertical;
    }
    @supports not (-webkit-line-clamp: $lines) {
      @include ellipsis;
    }
  } @else {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@mixin button1 {
  font-size: 14px;
  font-weight: 500;
}

@mixin button2 {
  font-size: 12px;
  font-weight: 500;
}

/**
 * Layout
 */

@mixin flex($direction, $justify, $align) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

//Col
@mixin flexColStartStart {
  @include flex(column, flex-start, flex-start);
}
@mixin flexColStartCenter {
  @include flex(column, flex-start, center);
}
@mixin flexColStartEnd {
  @include flex(column, flex-start, flex-end);
}

@mixin flexColCenterStart {
  @include flex(column, center, flex-start);
}
@mixin flexColCenterCenter {
  @include flex(column, center, center);
}
@mixin flexColCenterEnd {
  @include flex(column, center, flex-end);
}

@mixin flexColEndStart {
  @include flex(column, flex-end, flex-start);
}
@mixin flexColEndCenter {
  @include flex(column, flex-end, center);
}
@mixin flexColEndEnd {
  @include flex(column, flex-end, flex-end);
}

//Row
@mixin flexRowStartStart {
  @include flex(row, flex-start, flex-start);
}
@mixin flexRowStartCenter {
  @include flex(row, flex-start, center);
}
@mixin flexRowStartEnd {
  @include flex(row, flex-start, flex-end);
}

@mixin flexRowCenterStart {
  @include flex(row, center, flex-start);
}
@mixin flexRowCenterCenter {
  @include flex(row, center, center);
}
@mixin flexRowCenterEnd {
  @include flex(row, center, flex-end);
}

@mixin flexRowEndStart {
  @include flex(row, flex-end, flex-start);
}
@mixin flexRowEndCenter {
  @include flex(row, flex-end, center);
}
@mixin flexRowEndEnd {
  @include flex(row, flex-end, flex-end);
}

@mixin flexRowSpaceBetweenStart {
  @include flex(row, space-between, flex-start);
}
@mixin flexRowSpaceBetweenCenter {
  @include flex(row, space-between, center);
}
@mixin flexRowSpaceBetweenEnd {
  @include flex(row, space-between, flex-end);
}

// Absolute
@mixin absoluteHorizontalCenter {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}

/*
  Bootstrap mixins
 */
// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin border-radius($radius: $border-radius) {
  border-radius: valid-radius($radius);
}

@mixin border-top-radius($radius) {
  border-top-left-radius: valid-radius($radius);
  border-top-right-radius: valid-radius($radius);
}

@mixin border-right-radius($radius) {
  border-top-right-radius: valid-radius($radius);
  border-bottom-right-radius: valid-radius($radius);
}

@mixin border-bottom-radius($radius) {
  border-bottom-right-radius: valid-radius($radius);
  border-bottom-left-radius: valid-radius($radius);
}

@mixin border-left-radius($radius) {
  border-top-left-radius: valid-radius($radius);
  border-bottom-left-radius: valid-radius($radius);
}

@mixin border-top-left-radius($radius) {
  border-top-left-radius: valid-radius($radius);
}

@mixin border-top-right-radius($radius) {
  border-top-right-radius: valid-radius($radius);
}

@mixin border-bottom-right-radius($radius) {
  border-bottom-right-radius: valid-radius($radius);
}

@mixin border-bottom-left-radius($radius) {
  border-bottom-left-radius: valid-radius($radius);
}

@mixin hover-focus() {
  &:hover,
  &:focus {
    @content;
  }
}

@mixin color-variant($type) {
  $color: map.get($theme-colors, $type);
  $light-color: map.get($theme-light-colors, $type);
  color: $color;
  background-color: $light-color;
  &.color-inverted {
    background-color: $color;
    color: $white;
  }
}

@mixin button-variant(
  $color,
  $background,
  $border,
  $hover-color,
  $hover-background,
  $hover-border,
  $pressed-color,
  $pressed-background,
  $pressed-border
) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:hover,
  &:focus,
  .focus {
    color: $hover-color;
    background-color: $hover-background;
    border-color: $hover-border;
    box-shadow: none;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: $pressed-color;
    background-color: $pressed-background;
    border-color: $pressed-border;
    box-shadow: none;
  }
}

@mixin transition($transition...) {
  @if length($transition) == 0 {
    $transition: $transition-base;
  }

  @if length($transition) > 1 {
    @each $value in $transition {
      @if $value == null or $value == none {
        @warn "The keyword 'none' or 'null' must be used as a single argument.";
      }
    }
  }

  @if nth($transition, 1) != null {
    transition: $transition;
  }

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
}

@mixin hover() {
  &:hover {
    @content;
  }
}

// Button sizes
@mixin button-size($padding-y, $padding-x, $font-size, $line-height, $border-radius) {
  padding: $padding-y $padding-x;
  @include font-size($font-size);
  line-height: $line-height;
  // Manually declare to provide an override to the browser default
  border-radius: $border-radius-small;
}

@mixin generate-utility($utility, $infix, $is-rfs-media-query: false) {
  $values: map-get($utility, values);

  // If the values are a list or string, convert it into a map
  @if type-of($values) == 'string' or type-of(nth($values, 1)) != 'list' {
    $values: zip($values, $values);
  }

  @each $key, $value in $values {
    $properties: map-get($utility, property);

    // Multiple properties are possible, for example with vertical or horizontal margins or paddings
    @if type-of($properties) == 'string' {
      $properties: append((), $properties);
    }

    // Use custom class if present
    $property-class: if(map-has-key($utility, class), map-get($utility, class), nth($properties, 1));
    $property-class: if($property-class == null, '', $property-class);

    $pseudo: if(map-has-key($utility, pseudo), map-get($utility, pseudo), null);
    $pseudo: if($pseudo == null, '', ':' + $pseudo);

    $infix: if($property-class == '' and str-slice($infix, 1, 1) == '-', str-slice($infix, 2), $infix);

    // Don't prefix if value key is null (eg. with shadow class)
    $property-class-modifier: if($key, if($property-class == '' and $infix == '', '', '-') + $key, '');

    @if map-get($utility, rfs) {
      // Inside the media query
      @if $is-rfs-media-query {
        $val: rfs-value($value);

        // Do not render anything if fluid and non fluid values are the same
        $value: if($val == rfs-fluid-value($value), null, $val);
      } @else {
        $value: rfs-fluid-value($value);
      }
    }

    @if $value != null {
      .#{$property-class + $infix + $property-class-modifier + $pseudo} {
        @each $property in $properties {
          #{$property}: $value if($enable-important-utilities, !important, null);
        }
      }
    }
  }
}

@mixin chip {
  @include flexRowCenterCenter;
  @include body2;
  position: relative;
  display: inline-flex;
  padding: 0 $spacing-small-medium;
  min-height: $chip-height;
  border-radius: $chip-height * 0.5;
  border: 0.5px solid $secondary-medium-light-color;
  background-color: $secondary-light-color;
  color: $text-color;
  outline-width: 0;
}
