@use 'sass:map';

:root {
  --ck-font-face: 'Roboto', Arial, sans-serif !important;
}

.ck.ck-balloon-panel {
  --ck-color-panel-background: #{$white};
  --ck-color-panel-border: #{$background-color};
  --ck-color-tooltip-background: #{$white};
  --ck-color-tooltip-text: $text-color;
  box-shadow: $shadow-8 !important;
  border-radius: $border-radius-small !important;
}

.ck.ck-balloon-panel.ck-tooltip {
  padding: $spacing-small $spacing-medium !important;
  padding: $spacing-x-small spacing-small !important;
}

.ck.ck-balloon-panel.ck-tooltip .ck-tooltip__text {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.ck.ck-link-actions .ck-button.ck-link-actions__preview .ck-button__label {
  color: $primary-color !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  &:hover {
    text-decoration: none !important;
  }
}

.ck.ck-label {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.ck.ck-input.ck-input-text {
  height: 40px;
  border-color: $text-20-color !important;
  border-radius: 4px !important;
  color: $text-color !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  &:focus {
    box-shadow: none !important;
    border-color: $text-60-color !important;
  }
}

.ck.ck-button {
  @extend .btn;
  @extend .btn-type-tertiary;
  @extend .btn-secondary;
  width: $btn-height !important;
  height: $btn-height !important;
  padding: 0 !important;
  cursor: pointer !important;
  color: $text-color !important;
  border-radius: $border-radius-small !important;
  border-color: transparent !important;
  &:focus,
  &.active,
  &:active {
    outline: none !important;
  }
  &:hover,
  &:focus,
  .focus {
    box-shadow: none !important;
  }
  &::before {
    font: normal normal 400 14px/1 $font-family-fa-icons;
  }
  > svg {
    display: none;
  }
  &.ck-disabled {
    pointer-events: none !important;
    color: $disabled-color !important;
  }
}

.ck.ck-responsive-form,
.ck.ck-link-form {
  gap: $spacing-small;
  align-items: center;
  padding: $spacing-small !important;
  > * {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  > .ck.ck-labeled-field-view {
    margin-right: $spacing-small !important;
  }
}

// Hide the additional text
.ck.ck-link-actions .ck-button:nth-child(1) {
  display: none !important;
}

// Save button
.ck.ck-responsive-form > .ck.ck-button.ck-button-save {
  order: 2;
  &::before {
    content: '\f00c';
  }
}

// Cancel button
.ck.ck-responsive-form > .ck.ck-button.ck-button-cancel {
  order: 1;
  &::before {
    content: '\f00d';
  }
}

// Edit button
.ck.ck-link-actions .ck-button:nth-child(2) {
  order: 2;
  &::before {
    content: '\f304';
  }
}

// Unlink button
.ck.ck-link-actions .ck-button:nth-child(3) {
  order: 1;
  &::before {
    content: '\f127';
  }
}

// Position the label in the center by default
[dir='ltr']
  .ck.ck-labeled-field-view.ck-labeled-field-view_empty:not(.ck-labeled-field-view_focused):not(
    .ck-labeled-field-view_placeholder
  )
  > .ck.ck-labeled-field-view__input-wrapper
  > .ck.ck-label {
  transform: translate(10px, 12px) scale(1) !important;
}
