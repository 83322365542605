@use 'sass:map';
@import 'mixins';

/**
 * Text styles
 */

.text-body1 {
  @include body1;
}

.text-body2 {
  @include body2;
}

.text-body3 {
  @include body3;
}

.text-caption1 {
  @include caption1;
}

.text-overline {
  @include overline;
}

.text-button1 {
  @include button1;
}

.ellipsis {
  @include ellipsis;
}

/**
 * Color styles
 */

// Creating Color-Styles (text and background) depending on the Framework colors (primary, accent, success, danger, ...)
@each $color in map.keys($theme-colors) {
  .color-#{$color} {
    @include color-variant($color);
  }
}

// ----------------------------------------------------

$enable-important-utilities: true;

$bg-hover-focus: (
  'primary' rgb(var(--primary-hover-color)),
  'primary-medium' rgb(var(--primary-hover-color)),
  'secondary' rgb(var(--secondary-hover-color)),
  'secondary-medium' rgb(var(--secondary-hover-color)),
  'accent-1' darken($accent1, 10%),
  'accent-2' darken($accent2, 10%),
  'accent-3' darken($accent3, 10%),
  'accent-4' darken($accent4, 10%),
  'accent-5' darken($accent5, 10%),
  'accent-6' darken($accent6, 10%),
  'accent-7' darken($accent7, 10%),
  'accent-8' darken($accent8, 10%),
  'accent-9' darken($accent9, 10%),
  'accent-10' darken($accent10, 10%),
  'success' $success-hover,
  'warning' $warning-hover,
  'danger' $danger-hover
);

/**
  classes 1-7 are there for backwards compatibility after removing bootstrap.
  xxs-xxl classes should be prioritized.
 */
$spacers: (
  0: 0,
  'auto': auto,
  'xxs': $spacing-xx-small,
  'xs': $spacing-x-small,
  '1': $spacing-x-small,
  's': $spacing-small,
  '2': $spacing-small,
  'm': $spacing-medium,
  '3': $spacing-medium,
  'ml': $spacing-medium-large,
  '4': $spacing-medium-large,
  'l': $spacing-large,
  '5': $spacing-large,
  'xl': $spacing-x-large,
  '6': $spacing-x-large,
  'xxl': $spacing-xx-large,
  '7': $spacing-xx-large,
);

$utilities: (
  'display': (
    responsive: true,
    print: true,
    property: display,
    class: d,
    values: none inline inline-block block table table-row table-cell flex inline-flex grid inline-grid,
  ),
  // Sizing utilities
  'width':
    (
      property: width,
      class: w,
      values: (
        100: 100%,
        auto: auto,
      ),
    ),
  'height': (
    property: height,
    class: h,
    values: (
      100: 100%,
      auto: auto,
    ),
  ),
  // Margin utilities
  'margin':
    (
      responsive: true,
      property: margin,
      class: m,
      values: $spacers,
    ),
  'margin-x': (
    responsive: true,
    property: margin-right margin-left,
    class: mx,
    values: $spacers,
  ),
  'margin-y': (
    responsive: true,
    property: margin-top margin-bottom,
    class: my,
    values: $spacers,
  ),
  'margin-top': (
    responsive: true,
    property: margin-top,
    class: mt,
    values: $spacers,
  ),
  'margin-right': (
    responsive: true,
    property: margin-right,
    class: mr,
    values: $spacers,
  ),
  'margin-bottom': (
    responsive: true,
    property: margin-bottom,
    class: mb,
    values: $spacers,
  ),
  'margin-left': (
    responsive: true,
    property: margin-left,
    class: ml,
    values: $spacers,
  ),
  // Padding utilities
  'padding':
    (
      responsive: true,
      property: padding,
      class: p,
      values: $spacers,
    ),
  'padding-x': (
    responsive: true,
    property: padding-right padding-left,
    class: px,
    values: $spacers,
  ),
  'padding-y': (
    responsive: true,
    property: padding-top padding-bottom,
    class: py,
    values: $spacers,
  ),
  'padding-top': (
    responsive: true,
    property: padding-top,
    class: pt,
    values: $spacers,
  ),
  'padding-right': (
    responsive: true,
    property: padding-right,
    class: pr,
    values: $spacers,
  ),
  'padding-bottom': (
    responsive: true,
    property: padding-bottom,
    class: pb,
    values: $spacers,
  ),
  'padding-left': (
    responsive: true,
    property: padding-left,
    class: pl,
    values: $spacers,
  ),
  'gap': (
    responsive: true,
    property: gap,
    class: gap,
    values: $spacers,
  ),
  // Text
  'text-transform':
    (
      property: text-transform,
      class: text,
      values: lowercase uppercase capitalize,
    ),
  'text-align': (
    responsive: true,
    property: text-align,
    class: text,
    values: left right center,
  ),
  'color': (
    property: color,
    class: text,
    values: (
      'default': inherit,
      'gray': $gray,
      'white': $white,
      'color': $text-color,
      '10': $text-10-color,
      '20': $text-20-color,
      '60': $text-60-color,
      '80': $text-80-color,
      'success': $success,
      'warning': $warning,
      'danger': $danger,
      'info': $info,
      'link': $primary-color,
      'primary-light': $primary-light-color,
      'primary': $primary-color,
      'primary-medium-dark': $primary-medium-dark-color,
      'secondary': $secondary-color,
      'secondary-medium': $secondary-medium-color,
      'secondary-dark': $secondary-dark-color,
      'transparent': transparent,
      'accent-1': $accent1,
      'accent-1-light': $accent1-light,
      'accent-2': $accent2,
      'accent-2-light': $accent2-light,
      'accent-3': $accent3,
      'accent-3-light': $accent3-light,
      'accent-4': $accent4,
      'accent-4-light': $accent4-light,
      'accent-5': $accent5,
      'accent-5-light': $accent5-light,
      'accent-6': $accent6,
      'accent-6-light': $accent6-light,
      'accent-7': $accent7,
      'accent-7-light': $accent7-light,
      'accent-8': $accent8,
      'accent-8-light': $accent8-light,
      'accent-9': $accent9,
      'accent-9-medium': $accent9-medium,
      'accent-9-light': $accent9-light,
      'accent-10': $accent10,
      'accent-10-light': $accent10-light,
      'accent-11': $accent11,
      'accent-11-light': $accent11-light,
      'accent-12': $accent12,
      'accent-13': $accent13,
      'accent-13-light': $accent13-light,
    ),
  ),
  'background-color': (
    property: background-color,
    class: bg,
    values: (
      'gray': $gray,
      'white': $white,
      'primary': $primary-color,
      'primary-medium-dark': $primary-medium-dark-color,
      'primary-medium': $primary-medium-color,
      'primary-medium-light': $primary-medium-light-color,
      'primary-light': $primary-light-color,
      'secondary': $secondary-color,
      'secondary-dark': $secondary-dark-color,
      'secondary-medium-dark': $secondary-medium-dark-color,
      'secondary-medium': $secondary-medium-color,
      'secondary-medium-light': $secondary-medium-light-color,
      'secondary-light': $secondary-light-color,
      'transparent': transparent,
      'color': $background-color,
      'light': $background-light-color,
      'accent-1': $accent1,
      'accent-1-light': $accent1-light,
      'accent-2': $accent2,
      'accent-2-light': $accent2-light,
      'accent-3': $accent3,
      'accent-3-light': $accent3-light,
      'accent-4': $accent4,
      'accent-4-light': $accent4-light,
      'accent-5': $accent5,
      'accent-5-light': $accent5-light,
      'accent-6': $accent6,
      'accent-6-light': $accent6-light,
      'accent-7': $accent7,
      'accent-7-light': $accent7-light,
      'accent-8': $accent8,
      'accent-8-light': $accent8-light,
      'accent-9': $accent9,
      'accent-9-medium': $accent9-medium,
      'accent-9-light': $accent9-light,
      'accent-10': $accent10,
      'accent-10-light': $accent10-light,
      'accent-11': $accent11,
      'accent-11-light': $accent11-light,
      'accent-12': $accent12,
      'accent-13': $accent13,
      'accent-13-light': $accent13-light,
      'success': $success,
      'success-light': $success-light,
      'success-medium': $success-medium,
      'warning': $warning,
      'warning-medium-light': $warning-medium-light,
      'warning-light': $warning-light,
      'danger': $danger,
      'danger-medium-light': $danger-medium-light,
      'danger-light': $danger-light,
      'danger-medium': $danger-medium,
      'danger-dark': $danger-dark,
      'inactive': $inactive-color,
    ),
  ),
  'fill': (
    property: fill,
    class: fill,
    values: (
      'gray': $gray,
      'white': $white,
      'primary': $primary-color,
      'primary-medium-dark': $primary-medium-dark-color,
      'primary-medium': $primary-medium-color,
      'primary-medium-light': $primary-medium-light-color,
      'primary-light': $primary-light-color,
      'secondary': $secondary-color,
      'secondary-dark': $secondary-dark-color,
      'secondary-medium-dark': $secondary-medium-dark-color,
      'secondary-medium': $secondary-medium-color,
      'secondary-medium-light': $secondary-medium-light-color,
      'secondary-light': $secondary-light-color,
      'transparent': transparent,
      'color': $background-color,
      'light': $background-light-color,
      'accent-1': $accent1,
      'accent-1-light': $accent1-light,
      'accent-2': $accent2,
      'accent-2-light': $accent2-light,
      'accent-3': $accent3,
      'accent-3-light': $accent3-light,
      'accent-4': $accent4,
      'accent-4-light': $accent4-light,
      'accent-5': $accent5,
      'accent-5-light': $accent5-light,
      'accent-6': $accent6,
      'accent-6-light': $accent6-light,
      'accent-7': $accent7,
      'accent-7-light': $accent7-light,
      'accent-8': $accent8,
      'accent-8-light': $accent8-light,
      'accent-9': $accent9,
      'accent-9-medium': $accent9-medium,
      'accent-9-light': $accent9-light,
      'accent-10': $accent10,
      'accent-10-light': $accent10-light,
      'accent-11': $accent11,
      'accent-11-light': $accent11-light,
      'accent-12': $accent12,
      'accent-13': $accent13,
      'accent-13-light': $accent13-light,
      'success': $success,
      'success-light': $success-light,
      'success-medium': $success-medium,
      'warning': $warning,
      'warning-light': $warning-light,
      'danger': $danger,
      'danger-light': $danger-light,
      'danger-medium': $danger-medium,
      'danger-dark': $danger-dark,
    ),
  ),
  'stroke': (
    property: stroke,
    class: stroke,
    values: (
      'gray': $gray,
      'white': $white,
      'primary': $primary-color,
      'primary-medium-dark': $primary-medium-dark-color,
      'primary-medium': $primary-medium-color,
      'primary-medium-light': $primary-medium-light-color,
      'primary-light': $primary-light-color,
      'secondary': $secondary-color,
      'secondary-dark': $secondary-dark-color,
      'secondary-medium-dark': $secondary-medium-dark-color,
      'secondary-medium': $secondary-medium-color,
      'secondary-medium-light': $secondary-medium-light-color,
      'secondary-light': $secondary-light-color,
      'transparent': transparent,
      'color': $background-color,
      'light': $background-light-color,
      'accent-1': $accent1,
      'accent-1-light': $accent1-light,
      'accent-2': $accent2,
      'accent-2-light': $accent2-light,
      'accent-3': $accent3,
      'accent-3-light': $accent3-light,
      'accent-4': $accent4,
      'accent-4-light': $accent4-light,
      'accent-5': $accent5,
      'accent-5-light': $accent5-light,
      'accent-6': $accent6,
      'accent-6-light': $accent6-light,
      'accent-7': $accent7,
      'accent-7-light': $accent7-light,
      'accent-8': $accent8,
      'accent-8-light': $accent8-light,
      'accent-9': $accent9,
      'accent-9-medium': $accent9-medium,
      'accent-9-light': $accent9-light,
      'accent-10': $accent10,
      'accent-10-light': $accent10-light,
      'accent-11': $accent11,
      'accent-11-light': $accent11-light,
      'accent-12': $accent12,
      'accent-13': $accent13,
      'accent-13-light': $accent13-light,
      'success': $success,
      'success-light': $success-light,
      'success-medium': $success-medium,
      'warning': $warning,
      'warning-light': $warning-light,
      'danger': $danger,
      'danger-light': $danger-light,
      'danger-medium': $danger-medium,
      'danger-dark': $danger-dark,
    ),
  ),
  'background-color-hover': (
    property: background-color,
    class: bgh,
    pseudo: hover,
    values: $bg-hover-focus,
  ),
  'background-color-focus': (
    property: background-color,
    class: bgf,
    pseudo: focus,
    values: $bg-hover-focus,
  ),
  'text-decoration': (
    property: text-decoration,
    values: none underline line-through,
  ),
  'border-radius': (
    property: border-radius,
    class: br,
    values: (
      xs: $border-radius-x-small,
      s: $border-radius-small,
      m: $border-radius-medium,
    ),
  ),
);

//// GENERATOR
// Default utilities without media queries
@each $key, $utility in $utilities {
  // The utility can be disabled with `false`, thus check if the utility is a map first
  @if type-of($utility) == 'map' {
    @include generate-utility($utility, '');
  }
}
