.content-side-panel {
  flex: 1 1 content;
}

.side-panel-parent {
  display: flex;
  height: 100%;
  flex: 1 1 auto;
  flex-direction: row;
}
