@import '../../styles/variables';
@import '../../styles/mixins';

.fleo-ui-dropdown {
  display: block;
  border-radius: $border-radius-small;
  background-color: #fff;
  z-index: 1;
  width: min-content;
  box-shadow: $shadow-8;
  [data-overlayscrollbars-viewport] {
    max-height: 400px;
  }

  .dropdown-container {
    height: 100%;
    width: 100%;
    position: relative;
  }

  fleo-ui-list-item {
    &:first-child {
      .list-item {
        border-radius: $border-radius-small $border-radius-small 0 0;
      }
    }
    &:first-of-type {
      .list-item {
        .list-line {
          display: none;
        }
      }
    }
    &:last-child {
      .list-item {
        border-radius: 0 0 $border-radius-small $border-radius-small;
      }
    }
  }
  a:not(:first-of-type) {
    fleo-ui-list-item {
      .list-item {
        .list-line {
          display: block;
        }
      }
    }
  }
}
