@import '../../styles/variables';
@import '../../styles/mixins';

/** make ellipse for words that are longer than tooltip-max-width */
@mixin overflowHandling($maxWidth) {
  & > div {
    max-width: calc($maxWidth - $spacing-medium * 2);
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

@mixin tooltip {
  @include body2;
  transition: transform 100ms ease-in-out;
  box-shadow: $shadow-8;

  &:not(.tooltip-panel-blank) {
    border-color: $background-color;
    background-color: $white;
    border-radius: $border-radius-small;
    padding: $spacing-small $spacing-medium;
    max-height: 400px;
  }

  &:not(.has-template) {
    max-width: $tooltip-max-width;
    overflow: hidden;
    text-overflow: ellipsis;
    @include overflowHandling($tooltip-max-width);
  }

  &.is-overflow {
    max-width: $tooltip-overflow-max-width;
    word-wrap: anywhere;
    @include overflowHandling($tooltip-overflow-max-width);
  }
}

.fleo-ui-tooltip-panel {
  @include tooltip;
}
