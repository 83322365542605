@use 'sass:map';
@import 'variables';
@import 'mixins';

.btn {
  @include flexRowCenterCenter;
  display: inline-flex;
  border-radius: $border-radius-small;
  padding: 0 $spacing-medium;
  height: $btn-height;
  border: 1px solid $primary-color;
  user-select: none;

  @each $color, $value in $button-colors {
    &.btn-#{$color} {
      @include button-variant(
        $text-inverted-color,
        $value,
        transparent,
        $text-inverted-color,
        map.get($theme-hover-colors, $color),
        transparent,
        $text-inverted-color,
        map.get($theme-pressed-colors, $color),
        transparent
      );
    }
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    color: $text-inverted-color;
    background-color: $disabled-color;
    border-color: $disabled-color;
  }

  &.btn-s {
    height: $btn-height-small;
    padding: 0 $spacing-small;
    font-size: $font-size-s;
  }

  &:focus,
  &.active,
  &:active {
    outline: none;
  }

  // Secondary button style including all colors & behavior
  &.btn-type-secondary {
    @each $color, $value in $button-colors {
      &.btn-#{$color} {
        @include button-variant(
          $value,
          transparent,
          $value,
          $value,
          map.get($theme-light-colors, $color),
          $value,
          $value,
          map.get($theme-medium-colors, $color),
          $value
        );
      }
    }

    &:disabled,
    &.disabled {
      color: rgba(var(--disabled-color)) !important;
      background-color: transparent !important;
      border-color: rgba(var(--disabled-color)) !important;
    }
  }

  // Tertiary button style including all colors & behavior
  &.btn-type-tertiary {
    @each $color, $value in $button-colors {
      &.btn-#{$color} {
        @include button-variant(
          $value,
          transparent,
          transparent,
          $value,
          map.get($theme-light-colors, $color),
          transparent,
          $value,
          map.get($theme-medium-colors, $color),
          transparent
        );
      }
    }

    &:disabled,
    &.disabled {
      color: rgba(var(--disabled-color)) !important;
      background-color: transparent !important;
      border-color: transparent !important;
    }
  }
}
