@import 'variables';

html,
body {
  height: 100%;
}

body {
  font-family: $font-family-base;
  font-size: 14px;
  background-color: rgb(var(--theme-color));
}

small,
.small {
  @include font-size($font-size-s);
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: $headings-margin-bottom;
  line-height: $headings-line-height;
}

h1,
.h1,
.text-h1 {
  @include h1;
}

h2,
.h2,
.text-h2 {
  @include h2;
}

h3,
.h3,
.text-h3 {
  @include h3;
}

h4,
.h4,
.text-h4 {
  @include h4;
}

h5,
.h5,
.text-h5 {
  @include h5;
}

h6,
.h6,
.text-h6 {
  @include h6;
}

.h7,
.text-h7 {
  @include h7;
}

.h1,
.h2,
.h3,
h1,
h2,
h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.h3,
.h4,
.h5,
h3,
h4,
h5 {
  margin-top: 5px;
}

// Link
a {
  color: rgba(var(--primary-color));
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
  background-color: transparent;
  border: none;

  &:hover,
  &:focus {
    text-decoration: none;
    color: rgba(var(--primary-hover-color));
  }

  &:active {
    color: rgba(var(--primary-pressed-color));
  }

  &.disabled {
    color: rgba(var(--disabled-color));
    pointer-events: none;
  }
}

// Overwrites bootstrap _reboot.scss style
a:not([href]):not([class]) {
  color: rgba(var(--primary-color));
  text-decoration: none;

  @include hover() {
    color: rgba(var(--primary-hover-color));
    text-decoration: none;
  }
}

input {
  text-overflow: ellipsis;
}

.snackbar-overlay {
  pointer-events: none;
}

:root {
  --menu-text-color: #{hexToRGB(#fff)};
  --theme-color: #{hexToRGB(#0f2a48)};
  --highlight-color: #{hexToRGB(#00a1c6)};
  --hover-color: #{hexToRGB(#278aaf)};
  --logo-color: #{hexToRGB(#fff)};
  --content-background: #{hexToRGB(#f2f6fa)};
  --text-color: #{hexToRGB($text-color)};
  --text-dark-80-color: #{hexToRGB($text-80-color)};

  //primary
  --primary-light-color: #{hexToRGB(#e8f5f9)};
  --primary-medium-light-color: #{hexToRGB(#d9ecf3)};
  --primary-medium-color: #{hexToRGB(#b3d9e7)};
  --primary-medium-dark-color: #{hexToRGB(#8ec5da)};
  --primary-dark-color: #{hexToRGB(#68b2ce)};
  --primary-color: #{hexToRGB(#00a1c6)};
  --primary-hover-color: #{hexToRGB(#278aaf)};
  --primary-pressed-color: #{hexToRGB(#086b90)};

  //secondary
  --secondary-light-color: #{hexToRGB(#e8eaed)};
  --secondary-medium-light-color: #{hexToRGB(#d0d6de)};
  --secondary-medium-color: #{hexToRGB(#9faab6)};
  --secondary-medium-dark-color: #{hexToRGB(#737f90)};
  --secondary-dark-color: #{hexToRGB(#44556b)};
  --secondary-color: #{hexToRGB(#0f2a48)};
  --secondary-hover-color: #{hexToRGB(#061e38)};
  --secondary-pressed-color: #{hexToRGB(#011326)};

  //accent
  --accent-1-color: #{hexToRGB(#7b91ad)};
  --accent-2-color: #{hexToRGB(#ffbf0e)};
  --accent-3-color: #{hexToRGB(#e64522)};
  --accent-4-color: #{hexToRGB(#de637c)};
  --accent-5-color: #{hexToRGB(#ac395c)};
  --accent-6-color: #{hexToRGB(#943889)};
  --accent-7-color: #{hexToRGB(#6845a1)};
  --accent-8-color: #{hexToRGB(#006fb4)};
  --accent-9-color: #{hexToRGB(#3690e2)};
  --accent-10-color: #{hexToRGB(#007d99)};

  // Inactive
  --inactive-color: #{hexToRGB($inactive-color)};

  --disabled-color: #{hexToRGB(#d0d0d0)};

  --event-color: #{hexToRGB(#00538f)};

  --select-color: #{hexToRGB($select-color)};
  --select-hover-color: #{hexToRGB($select-hover-color)};

  --success-80-color: #{hexToRGB($success-dark)};
  --success-100-color: #{hexToRGB($success)};
  --success-110-color: #{hexToRGB($success-hover)};
  --success-120-color: #{hexToRGB($success-pressed)};

  --error-80-color: #{hexToRGB($danger-dark)};
  --error-100-color: #{hexToRGB($danger)};
  --error-110-color: #{hexToRGB($danger-hover)};
  --error-120-color: #{hexToRGB($danger-pressed)};

  --warning-80-color: #{hexToRGB($warning-dark)};
  --warning-100-color: #{hexToRGB($warning)};
  --warning-110-color: #{hexToRGB($warning-hover)};
  --warning-120-color: #{hexToRGB($warning-pressed)};

  --info-80-color: #{hexToRGB(#44556b)};
  --info-90-color: #{hexToRGB(#27415d)};
  --info-100-color: #{hexToRGB(#0f2a48)};
  --info-110-color: #{hexToRGB(#061e38)};
}
