@font-face {
  font-family: 'Aero';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/logo/Aero Book.eot'); /* IE9 */
  src: url('./fonts/logo/Aero Book.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/logo/Aero Book.woff2') format('woff2'),
    /* chrome、firefox、opera、Safari, Android, iOS */ url('./fonts/logo/Aero Book.woff') format('woff'),
    /* chrome、firefox */ url('./fonts/logo/Aero Book.ttf') format('truetype'),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+ */ url('./fonts/logo/Aero Book.svg#uxfonteditor') format('svg'); /* iOS 4.1- */
}

@font-face {
  font-family: 'Aero';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/logo/Aero Bold.eot'); /* IE9 */
  src: url('./fonts/logo/Aero Bold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/logo/Aero Bold.woff2') format('woff2'),
    /* chrome、firefox、opera、Safari, Android, iOS */ url('./fonts/logo/Aero Bold.woff') format('woff'),
    /* chrome、firefox */ url('./fonts/logo/Aero Bold.ttf') format('truetype'),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+ */ url('./fonts/logo/Aero Bold.svg#uxfonteditor') format('svg'); /* iOS 4.1- */
}

/* roboto-100 - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: local(''), url('./fonts/roboto-v30-latin-ext_latin-100.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./fonts/roboto-v30-latin-ext_latin-100.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-100italic - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  src: local(''), url('./fonts/roboto-v30-latin-ext_latin-100italic.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./fonts/roboto-v30-latin-ext_latin-100italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-300 - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local(''), url('./fonts/roboto-v30-latin-ext_latin-300.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./fonts/roboto-v30-latin-ext_latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-regular - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('./fonts/roboto-v30-latin-ext_latin-regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./fonts/roboto-v30-latin-ext_latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-300italic - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: local(''), url('./fonts/roboto-v30-latin-ext_latin-300italic.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./fonts/roboto-v30-latin-ext_latin-300italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-italic - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: local(''), url('./fonts/roboto-v30-latin-ext_latin-italic.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./fonts/roboto-v30-latin-ext_latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-500 - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local(''), url('./fonts/roboto-v30-latin-ext_latin-500.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./fonts/roboto-v30-latin-ext_latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-500italic - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: local(''), url('./fonts/roboto-v30-latin-ext_latin-500italic.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./fonts/roboto-v30-latin-ext_latin-500italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-700 - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local(''), url('./fonts/roboto-v30-latin-ext_latin-700.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./fonts/roboto-v30-latin-ext_latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-700italic - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: local(''), url('./fonts/roboto-v30-latin-ext_latin-700italic.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./fonts/roboto-v30-latin-ext_latin-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-900 - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: local(''), url('./fonts/roboto-v30-latin-ext_latin-900.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./fonts/roboto-v30-latin-ext_latin-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-900italic - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  src: local(''), url('./fonts/roboto-v30-latin-ext_latin-900italic.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./fonts/roboto-v30-latin-ext_latin-900italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
