@use 'sass:map';
@import 'functions';

// Colors
$gray: #717c80;
$white: #ffffff;
$focus-border-color: darken(#d0d4da, 25%);

$secondary-color: #0f2a48;
$secondary-dark-color: #44556b;
$secondary-medium-dark-color: #737f90;
$secondary-medium-color: #9faab6;
$secondary-medium-light-color: #d0d6de;
$secondary-light-color: #e8eaed;

$primary-pressed-color: #086b90;
$primary-hover-color: #278aaf;
$primary-color: #00a1c6;
$primary-medium-dark-color: #8ec5da;
$primary-medium-color: #b3d9e7;
$primary-medium-light-color: #d9ecf3;
$primary-light-color: #e8f5f9;

$map-pin-color: #00538f;
$map-pin-dark-color: #004272;
$map-pin-medium-dark-color: #004b81;
$map-pin-color: #00538f;
$map-pin-medium-light-color: #3375a5;
$map-pin-light-color: #6698bc;

// Accent
$accent1: #7b91ad;
$accent1-light: #e5e9ef;
$accent2: #ffbf0e;
$accent2-light: #fff9e7;
$accent3: #e64522;
$accent3-light: #fadad3;
$accent4: #de637c;
$accent4-light: #f8e0e5;
$accent5: #ac395c;
$accent5-light: #eed7de;
$accent6: #943889;
$accent6-light: #f2daee;
$accent7: #6845a1;
$accent7-light: #e1daec;
$accent8: #006fb4;
$accent8-light: #cce2f0;
$accent9: #0080ff;
$accent9-medium: #99ccff;
$accent9-light: #e5f2ff;
$accent10: #007d99;
$accent10-light: #cce5eb;
$accent11: #007d99;
$accent11-light: #d4d9df;
$accent12: #00538f;
$accent13: #ff7d05;
$accent13-light: #fff2e6;

// Text
$text-color: #131516;
$text-10-color: #f3f4f6;
$text-20-color: #d0d0d0;
$text-40-color: #a1a1a2;
$text-60-color: #717c80;
$text-80-color: #383d3f;
$text-inverted-color: white;
$text-pressed: #131516;
$text-main-subtitle: #717373;

// Background
$background-color: #f2f6fa;
$background-light-color: #f8fbff;
$background-hover-color: #e5ecf3;
$background-pressed-color: #d7e3ee;

$disabled-color: $text-20-color;

// Warning
$warning: #ff9900;
$warning-light: #fff5e5;
$warning-medium-light: #ffebcc;
$warning-medium: #ffc266;
$warning-hover: #e58a00;
$warning-dark: #ffad33;
$warning-pressed: #cc7a00;
$warning-disabled: $disabled-color;

// Danger
$danger: #bb021a;
$danger-light: #f9ebed;
$danger-medium-light: #f1ccd1;
$danger-medium: #e49aa3;
$danger-dark: #c93448;
$danger-hover: #a10015;
$danger-pressed: #890112;
$danger-disabled: $disabled-color;

// Success
$success: #138365;
$success-light: #e7f3f0;
$success-medium: #a1cdc1;
$success-dark: #188c6c;
$success-hover: #006a57;
$success-pressed: #138365;
$success-disabled: $text-20-color;

// Info
$info: $primary-color !default;
$info-hover: $primary-hover-color;
$info-pressed: $primary-pressed-color;
$info-disabled: $disabled-color;

// Inactive
$inactive-color: #fafafa;

// Border
$border-radius-x-small: 2px;
$border-radius-small: 4px;
$border-radius-medium: 8px;

// Controls
$control-border: $secondary-medium-light-color;
$form-control-radius: 4px;
$control-height: 32px;
$panel-navigation-height: 40px;

// Buttons
$btn-height: 32px;
$btn-height-small: 22px;

// Tables
$table-min-height: 320px;

// Select
$select-item-height: 32px;

// Checkbox
$checkbox-size: 18px;
$checkbox-border-width: 2px;
$checkbox-transition-duration: 90ms;

$linear-out-slow-in-timing-function: cubic-bezier(0, 0, 0.2, 0.1) !default;

// Shadow
$shadow-level-1: 0px 4px 10px rgba(0, 0, 0, 0.1);
$page-scroll-shadow-top: 0 3px 4px rgba(0, 0, 0, 0.1);
$shadow-8: 0px 2px 8px 0 rgba(#001c3c, 15%);
$shadow-16: 0px 4px 16px 0 rgba(#001c3c, 15%);
$shadow-32: 0px 8px 32px 0 rgba(#001c3c, 15%);

// Transition
$scroll-shadow-transition: box-shadow 0.3s ease-out;

// Spacings
$spacing-xx-small: 2px;
$spacing-x-small: 4px;
$spacing-small: 8px;
$spacing-small-medium: 12px;
$spacing-medium: 16px;
$spacing-medium-large: 24px;
$spacing-large: 32px;
$spacing-x-large: 64px;
$spacing-xx-large: 128px;

// Details Search Width
$details-table-search-width: 220px;

//Change Tracking
$change-tracking-row-height: 48px;

// Header
$header-local-height: 72px;
$header-horizontal-spacing: $spacing-medium;
$header-global-height: 56px;
$complex-header-illustration-size: 40px;

// Scrollbar
$scrollbar-width: 18px;
$scrollbar-height: 18px;

// Card
$card-min-width: 280px;
$wide-card-height: 72px;

// Chips
$chip-min-width: 38px;
$chip-height: 20px;

// Avatar
$avatar-size-xs: 20px;
$avatar-size-s: 32px;
$avatar-size-m: 40px;
$avatar-size-l: 64px;

// Event Tile
$tile-size-xs: 15px;
$tile-size-s: 24px;
$tile-size-m: 28px;
$tile-size-l: 46px;

// Icon
$icon-size-s: 10px;
$icon-size-m: 14px;
$icon-size-l: 24px;
$icon-size-xl: 32px;
$icon-size-xxl: 64px;

// Tooltip
$tooltip-max-width: 275px;
$tooltip-overflow-max-width: 600px;

// Modal
$modal-header: 64px;
$modal-height-m: 540px;
$modal-width-xs: 460px;
$modal-width-s: 664px;
$modal-width-m: 896px;
$modal-backdrop-color: rgba(0, 0, 0, 0.4);

$modal-grid-item-min-width-s: 280px;

// Sidepanel
$sidepanel-width-l: 720px;
$sidepanel-width-m: 520px;
$sidepanel-width-s: 460px;
$sidepanel-width-xs: 360px;
$sidepanel-grid-spacing: calc(#{$spacing-medium} + #{$spacing-small});
$sidepanel-footer-height: 48px;

// Panel
$panel-min-width: 100px;
$navigation-expanded-size: 220px;
$navigation-collapsed-size: 56px;

// Empty state
$empty-state-width-l: 384px;
$empty-state-width: 256px;
$empty-state-icon-m: 100px;
$empty-state-icon-l: 148px;

// Tabs
$tab-s-height: 48px;
$tab-m-height: 28px;
$tab-l-height: 32px;

// Input
$input-height: 32px;

// Page
$page-filter-min-width: 280px;
$page-header-filter-width: 200px;

// Quickfilter
$quickfilter-width: 10rem;

// Footer
$footer-height: 32px;

// Table
$row-height: 56px;
$header-height: 48px;

// Theme settings
$button-colors: (
  'primary': $primary-color,
  'secondary': $secondary-color,
  'danger': $danger,
  'warning': $warning,
  'success': $success,
  'info': $primary-color,
  'text': $text-80-color,
);

$theme-colors: (
  'primary': $primary-color,
  'primary-medium': $primary-medium-color,
  'secondary': $secondary-color,
  'secondary-medium': $secondary-medium-color,
  'accent-1': $accent1,
  'accent-2': $accent2,
  'accent-3': $accent3,
  'accent-4': $accent4,
  'accent-5': $accent5,
  'accent-6': $accent6,
  'accent-7': $accent7,
  'accent-8': $accent8,
  'accent-9': $accent9,
  'accent-10': $accent10,
  'accent-11': $accent11,
  'accent-12': $accent12,
  'accent-13': $accent13,
  'success': $success,
  'success-medium': $success-medium,
  'warning': $warning,
  'warning-medium': $warning-medium,
  'danger': $danger,
  'danger-medium': $danger-medium,
  'info': $primary-color,
  'text': $text-80-color,
);

$theme-hover-colors: (
  'primary': $primary-hover-color,
  'danger': $danger-hover,
  'warning': $warning-hover,
  'success': $success-hover,
  'info': $info-hover,
);

$theme-pressed-colors: (
  'primary': $primary-pressed-color,
  'danger': $danger-pressed,
  'warning': $warning-pressed,
  'success': $success-pressed,
  'info': $info-pressed,
);

$theme-light-colors: (
  'primary': $primary-light-color,
  'primary-medium': $primary-light-color,
  'secondary': $secondary-light-color,
  'secondary-medium': $secondary-light-color,
  'accent-1': $accent1-light,
  'accent-2': $accent2-light,
  'accent-3': $accent3-light,
  'accent-4': $accent4-light,
  'accent-5': $accent5-light,
  'accent-6': $accent6-light,
  'accent-7': $accent7-light,
  'accent-8': $accent8-light,
  'accent-9': $accent9-light,
  'accent-10': $accent10-light,
  'accent-11': $accent11-light,
  'accent-12': $accent12,
  'accent-13': $accent13-light,
  'success': $success-light,
  'warning': $warning-light,
  'danger': $danger-light,
  'info': $primary-light-color,
  'text': rgba($text-color, 0.1),
);

$theme-medium-colors: (
  'primary': $primary-medium-color,
  'danger': $danger-medium,
  'warning': $warning-medium,
  'success': $success-medium,
  'info': $primary-medium-color,
  'text': rgba($text-color, 0.2),
);

// Selection
$select-color: #f2fafc;
$select-hover-color: #f7fcfd;

//Body
$body-bg: $white;
$body-color: $text-color;

// Events
$event-color: #00538f;

$border-color: #e7eaec !default;
$border-main-light-color: #d2d7d8 !default;
$headings-margin-bottom: $spacing-small !default;
$headings-line-height: 1.2 !default;

$font-size-s: 12px !default;
$font-size: 14px !default;

$line-height-base: 1.5 !default;

$paragraph-margin-bottom: 1rem !default;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !default;

$input-btn-padding-y: 0.375rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-font-size: $font-size !default;
$input-btn-line-height: $line-height-base !default;

$btn-padding-y: $input-btn-padding-y !default;
$btn-padding-x: $input-btn-padding-x !default;
$btn-font-size: $input-btn-font-size !default;
$btn-line-height: $input-btn-line-height !default;

$transition-base: all 0.2s ease-in-out !default;
$btn-transition:
  color 0.15s ease-in-out,
  background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out !default;
$btn-focus-box-shadow: 0 0 0 0.2rem rgba($primary-color, 0.25) !default;
$btn-disabled-opacity: 0.65 !default;
$btn-focus-width: 0.2rem !default;

$form-text-margin-top: 0.25rem !default;

$form-check-input-gutter: 1.25rem !default;
$form-check-input-margin-y: 0.3rem !default;
$form-check-input-margin-x: 0.25rem !default;

$form-check-inline-margin-x: 0.75rem !default;
$form-check-inline-input-margin-x: 0.3125rem !default;

$form-grid-gutter-width: 10px !default;

$input-color: inherit !default;
$input-border-color: #ced4da !default;
$input-border-width: 1px !default;
$input-box-shadow: none !default;

$yiq-contrasted-threshold: 150 !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
) !default;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
) !default;

// Badges
$badge-height: 18px !default;
$badge-font-size: 9px !default;
$badge-border-radius-s: $border-radius-small !default;
$badge-border-radius-m: 9px !default;

$badge-transition: $btn-transition !default;
$badge-focus-width: 0.2rem !default;

$badge-pill-padding-x: 0.6em !default;
// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.
$badge-pill-border-radius: 10rem !default;

$grid-columns: 12 !default;
$grid-gutter-width: $spacing-medium; // Spacing of the bootstrap grid system
$grid-row-columns: 6 !default;

$font-name-roboto: 'Roboto';
$font-path: './fonts' !default;
$font-name-roboto-condensed: 'Roboto Condensed';
$font-path: './fonts' !default;

$font-family-base: $font-name-roboto, Arial, sans-serif;
$font-family-fa-icons: 'Font Awesome 6 Pro';

// Forms

$label-margin-bottom: 0.5rem !default;

$input-padding-y: $input-btn-padding-y !default;
$input-padding-x: $input-btn-padding-x !default;
$input-font-family: $font-family-base !default;
$input-font-size: 14px !default;
$input-font-weight: 400 !default;
$input-line-height: $input-btn-line-height !default;

$input-bg: $white !default;
$input-disabled-bg: $text-20-color !default;

$input-border-radius: $border-radius-small !default;
$input-border-radius-lg: $border-radius-small !default;
$input-border-radius-sm: $border-radius-small !default;

$input-focus-bg: $input-bg !default;
$input-focus-border-color: #8993a2;
$input-focus-color: $input-color !default;
$input-focus-width: 0.2rem !default;
$input-focus-box-shadow: none !default;

$input-placeholder-color: $text-60-color !default;
$input-plaintext-color: $body-color !default;

$input-height-border: $input-border-width * 2 !default;

$input-height-inner: add($input-line-height * 1em, $input-padding-y * 2) !default;
$input-height-inner-half: add($input-line-height * 0.5em, $input-padding-y) !default;
$input-height-inner-quarter: add($input-line-height * 0.25em, $input-padding-y * 0.5) !default;

$input-height: add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false)) !default;

$input-transition:
  border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out !default;
