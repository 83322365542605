$checkbox-border-width: 2px;
$pseudo-checkbox-padding: $checkbox-border-width * 2;
$pseudo-checkmark-size: $checkbox-size - (2 * $pseudo-checkbox-padding);

.pseudo-checkbox {
  width: $checkbox-size;
  height: $checkbox-size;
  border: $checkbox-border-width solid;
  border-radius: 2px;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;
  position: relative;
  flex-shrink: 0;

  &::after {
    position: absolute;
    opacity: 0;
    content: '';
    border-bottom: $checkbox-border-width solid currentColor;
    transition: opacity $checkbox-transition-duration $linear-out-slow-in-timing-function;
  }

  &.pseudo-checkbox-checked,
  &.pseudo-checkbox-indeterminate {
    border-color: transparent;
    background-color: $primary-color;
  }
}

.pseudo-checkbox-disabled {
  cursor: default;
  color: $text-20-color;

  &.pseudo-checkbox-checked,
  &.pseudo-checkbox-indeterminate {
    border-color: transparent;
    background-color: $text-20-color;
  }
}

.pseudo-checkbox-indeterminate::after {
  top: ($checkbox-size - $checkbox-border-width) * 0.5 - $checkbox-border-width;
  left: $checkbox-border-width * 0.5;
  width: $checkbox-size - 6px;
  opacity: 1;
  color: white;
  border-radius: 2px;
}

.pseudo-checkbox-checked::after {
  top: ($checkbox-size * 0.5) - ($pseudo-checkmark-size * 0.25) - ($checkbox-size * 0.1) - $checkbox-border-width;
  left: $pseudo-checkbox-padding - $checkbox-border-width * 1.5;
  width: $pseudo-checkmark-size;
  height: ($pseudo-checkmark-size - $checkbox-border-width) * 0.5;
  border-left: $checkbox-border-width solid currentColor;
  transform: rotate(-45deg);
  opacity: 1;
  box-sizing: content-box;
  color: white;
}

fleo-ui-checkbox.ng-invalid.ng-touched {
  .pseudo-checkbox {
    background-color: $danger-light !important;
    color: $danger;
    &.pseudo-checkbox-checked,
    &.pseudo-checkbox-indeterminate {
      background-color: $danger !important;
    }
  }
}
