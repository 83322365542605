label {
  @include caption1;
  margin-bottom: $spacing-x-small;
  color: $text-60-color;
}

.form-control {
  font-size: 14px;
  background-color: $white;
  background-image: none;
  border: 1px solid $control-border;
  border-radius: 4px;
  color: inherit;
  padding: 6px $spacing-small;
  transition:
    border-color 0.15s ease-in-out 0s,
    box-shadow 0.15s ease-in-out 0s;
  width: 100%;
  min-height: $control-height;
}

.form-control:focus {
  border-color: darken(#d0d4da, 25%);
  box-shadow: none;
}

.has-warning .form-control,
.has-warning .form-control:focus {
  border-color: $warning;
}

.has-error .form-control,
.has-error .form-control:focus {
  border-color: $danger;
  background-color: $danger-light;
}

.ng-invalid.form-control:not(form).ng-touched {
  border-color: $danger;
  background-color: $danger-light;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: $white;
  color: rgb(var(--secondary-medium-light-color));
  border-color: rgb(var(--secondary-medium-light-color));
}

.validation-error {
  @include caption1;
  color: $danger;
  margin-top: $spacing-x-small;
}

.validation-warning {
  @include caption1;
  color: $warning;
  margin-top: $spacing-x-small;
}

.input-warning {
  border-color: $warning !important;
  background-color: $warning-light;
}

.form-group {
  margin-bottom: 27px;
}

::placeholder {
  color: $text-60-color;
  opacity: 1;
}

.header-scroll-shadow {
  box-shadow: $page-scroll-shadow-top;
  position: relative;
  transition: box-shadow 0.3s ease-out;
  z-index: 1;
}
